<template>
  <genericImporterConfigs></genericImporterConfigs>
</template>

<script>
import { mapGetters } from 'vuex';
import genericImporterConfigs from './GenericImporterConfigs.vue';

export default {
  name: 'SFTPGenericImporterCreate',
  components: {
    genericImporterConfigs,
  },
  computed: {
    ...mapGetters({
      canAccessSftpFileImporters: 'User/canAccessSftpFileImporters',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      canAccessSftpFileTypes: 'User/canAccessSftpFileTypes',
    }),
  },
  beforeMount() {
    if (!this.canAccessSftpFileImporters || !this.isSftpSettingsEnabled || !this.canAccessSftpFileTypes) {
      this.$router.push('/not-found');
    }
  },
};
</script>

<style>
</style>
